import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import NFTDetailModal from '../NFTDetailModal';
import WalletModal from '../WalletModal';
import HelpModal from '../HelpModal';
import { Link } from "react-router-dom";
import OHTTooltip from "../../shared/Tooltip";
import { ACTION_CONST, ROUTES } from '../../../constants';
import { version } from '../../../../package.json';

import TransactionConfirmationModal from '../TransactionConfirmationModal/TransactionConfirmationModal';
import TransactionErrorContent from '../TransactionConfirmationModal/TransactionErrorContent';
import { useErrorModal, useMessage, useSuccessModal, useTransactionHash, useWaitingModal } from '../../../hook/useState';
import { useDispatch } from 'react-redux';




export default function Footer() {

  const dispatch = useDispatch();


  const openWaitingModal = useWaitingModal()
  const openErrorModal = useErrorModal()
  const openSuccessModal = useSuccessModal();
  const message = useMessage()
  const textHash = useTransactionHash()

  const clearState = () => {
    dispatch({ type: ACTION_CONST.HIDE_MODAL_SUBMIT })
    dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: "" });
    
  }
  
  return (
    <>
      <TransactionConfirmationModal isOpen={openWaitingModal} attemptingTxn={true} pendingText="" />
      <TransactionConfirmationModal isOpen={openSuccessModal} onDismiss={() => clearState()} hash={textHash} message={message} />
      <TransactionConfirmationModal isOpen={openErrorModal} onDismiss={() => clearState()}>
      <TransactionErrorContent message={message} onDismiss={() => clearState()} />
      </TransactionConfirmationModal>

      <div className="p-footer">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-6 text-center text-md-start">
              <div><img src="/images/logo_w.png" height="40" /></div>
              <div className="mt-3">Copyright © {new Date().getFullYear()} NFTLaunch Pad Company. All rights reserved-v.{version}</div>
            </div>
            <div className="col-md-6 text-center text-md-end mt-3 mt-md-0">
              <div className="d-flex align-items-center justify-content-center justify-content-md-end" style={{ gap: '15px', gridGap: '15px' }}>
                <a href="https://t.me/NFTLaunchofficial" target="_blank">
                  <OHTTooltip tooltip="NFTLaunch Official">
                    <i className="fab fa-telegram"></i>
                  </OHTTooltip>
                </a>
                <a href="https://twitter.com/NFT_LAUNCH" target="_blank">
                  <OHTTooltip tooltip="NFTLaunch Twitter">
                    <i className="fab fa-twitter"></i>
                  </OHTTooltip>
                </a>
                <a href="https://medium.com/@nftlaunch" target="_blank">
                  <OHTTooltip tooltip="NFTLaunch Medium">
                    <i className="fab fa-medium-m"></i>
                  </OHTTooltip>
                </a>
                <a href="https://nftlaunch.network/" target="_blank">
                  <OHTTooltip tooltip="NFTLaunch Website">
                    <i className="far fa-globe"></i>
                  </OHTTooltip>
                </a>
              </div>
              <div className="mt-3">
                <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <Link target="_blank" to={ROUTES.COOKIE_POLICY}>Cookies Policy</Link>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <Link target="_blank" to={ROUTES.TERMS_OF_USE}>Terms of Use</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
      <NFTDetailModal />
    </>
  );
}