import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACTION_CONST, connectors } from '../../constants';
import { connectorLocalStorageKey, ConnectorNames } from '../literals'
import useAuth from '../../hook/useAuth';
import { useActiveWeb3React } from '../../hook';
// import Web3Helper from '../../utils/useWeb3Utils'

const ConnectWalletModal = (props) => {

    //show hide button

    const { login } = useAuth();


    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }
    }, [])





    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="connectWalletModalLabel">Connect to wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (
                                        <button type="button" key={key} data-bs-dismiss="modal"
                                            onClick={() => handleConnectClick(entry)}
                                            id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                                            className="btn-select-network"
                                        >
                                            <img src={entry.icon} width="30px" alt="bscpad" />
                                            <div>{entry.title}</div>
                                        </button>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConnectWalletModal;

