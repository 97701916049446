import _ from "lodash";
import Web3 from "web3";
import { ACTION_STATUS, MESSAGE } from "../constants";
import ndoContractABI from "../constants/abi/ndo.json";
import { calculateBalanceSend } from "./utils";

/**
 * Get info Wallet
 * @param {*} provider
 * @param {*} contractAddress
 * @param {*} wallet
 * @returns
 */
export const getNdoWalletInfo = async (provider, contractAddress, wallet) => {
  const web3 = new Web3(provider);
  const ndoContract = new web3.eth.Contract(ndoContractABI, contractAddress);
  const walletInfo = await ndoContract.methods
    .infoWallet()
    .call({ from: wallet });

  const nativeBalance = walletInfo[0];
  const raiseTokenBalance = walletInfo[1];
  const tier = walletInfo[2];
  const roundState = parseInt(walletInfo[3]);
  const roundStateText = walletInfo[4];
  const roundTimestamp = parseInt(walletInfo[5]);
  const remainingAllocation = parseInt(walletInfo[6]);
  const userParticipation = walletInfo[7];

  return {
    nativeBalance,
    raiseTokenBalance,
    tier,
    roundState,
    roundStateText,
    roundTimestamp,
    remainingAllocation,
    userParticipation,
  };
};

/**
 * Get allocation of wallet
 * @param {*} provider
 * @param {*} contractAddress
 * @param {*} wallet
 * @returns
 */
export const getNdoAllocation = async (provider, contractAddress, wallet) => {
  const web3 = new Web3(provider);
  const ndoContract = new web3.eth.Contract(ndoContractABI, contractAddress);

  const contractInfoAllocation = await ndoContract.methods
    .infoNDOTokens()
    .call({ from: wallet });
  let infoAllocation = [];

  // const currentTimeStamp = new Date().getTime();

  for (let i = 0; i < contractInfoAllocation[1].length; i++) {
    infoAllocation.push({
      no: parseInt(contractInfoAllocation[1][i]),
      url: contractInfoAllocation[2][i],
      // tradeAble: currentTimeStamp >=  (parseInt(contractInfoAllocation[0])*1000)
    });
  }

  return {
    infoAllocation,
    launchTime: parseInt(contractInfoAllocation[0]),
  };
};

/**
 * participate function
 * @param {*} provider
 * @param {*} contractAddress
 * @param {*} wallet
 * @param {*} numberNfts
 * @param {*} value
 * @param {*} callback
 */

export const participateNdo = async (provider,contractAddress, wallet, numberNfts, value, callback) => {

  const web3 = new Web3(provider);
  const ndoContract = new web3.eth.Contract(ndoContractABI, contractAddress);

  value = calculateBalanceSend(value);
  const valueInHex = "0x" + value.toString(16);

  let sendObject = { from: wallet, value: valueInHex };
  
  const method = ndoContract.methods.participate(numberNfts);
  try {
    await web3.eth.call({
      from: wallet,
      to: contractAddress,
      data: method.encodeABI(),
      value: valueInHex,
    });

    //participate 
    method.send(sendObject).on("error", (error) => {
      console.log(error);
      callback({
        status: ACTION_STATUS.PARTICIPATE_FAIL,
        message: MESSAGE.PARTICIPATE_NDO_FAIL });
    })
    .then((receipt) => {
      if (receipt.status == true) {
        callback({
          status: ACTION_STATUS.PARTICIPATE_SUCCESS,
          txID: receipt.transactionHash,
        });
      } else callback({ status: ACTION_STATUS.PARTICIPATE_FAIL , message: MESSAGE.PARTICIPATE_NDO_FAIL});
    })
    .catch((err) => {
      console.log(err);
      callback({ status: ACTION_STATUS.PARTICIPATE_FAIL , message: MESSAGE.PARTICIPATE_NDO_FAIL});
    });

  } catch (err) {

    const message = err.message;

    console.log("message==>", typeof (message));
    debugger
    if (err.data) {
      const result = err.data.startsWith("0x") ? err.data : `0x${err.data}`;
      const reason = web3.utils.toAscii(`0x${result.substr(138)}`);
      console.log(`Revert reason: ${reason}`);
      callback({
        status: ACTION_STATUS.PARTICIPATE_FAIL,
        message: reason
      });
    } else {
      callback({
        status: ACTION_STATUS.ESTIMATE_GAS_FAILS,
        message: MESSAGE.ESTIMATE_GAS_FAILS
      });
    }
  }

  
};
