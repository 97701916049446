import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useOpeningProjects } from "../../hook/useState";


const OpeningProjectsComponent = (props) => {
  const dispatch = useDispatch();


  const openingProjects = useOpeningProjects();




  return (
    <div className="pp-investment pp-open">
      <div className="container text-center">
        <div className="row d-flex">
          <div className="col-lg-12 text-center">
            <div className="section-text">
              <h2 className="section-title">Projects Open Now</h2>
              <p className="section-description"></p>
            </div>
          </div>
        </div>

        <div className="investment-section-items">
          <div className="row gx-md-5 justify-content-xl-center">
            {openingProjects.length > 0 ?
              openingProjects.map((item, key) => {
                return (
                  <div className="col-xl-4 col-lg-6 col-md-6 text-center" key={key}>
                    <div className="level-item bordered project-item mb-4 mt-xl-3">
                      <div className="level-item-top">
                        {/* <span className="symbol">{item.raiseTokenSymbol}</span> */}
                        <span className="pp-status-open">Open</span>
                      </div>
                      <div className="item-social-top">
                        {
                          item.website &&
                          <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                        }
                        {
                          item.twitter &&
                          <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                        }
                        {
                          item.medium &&
                          <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                        }
                        {
                          item.telegram &&
                          <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                        }
                      </div>
                      <div className="bordered__inner bordered--double_angled bordered--bg_top">
                        <div className="level__inner pb-0 pt-3">
                          <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`} className="text-decoration-none  d-block pt-3">
                            <div className="level-item__img mb-3">
                              <img src={item.logo} alt="nft" />
                            </div>
                            <div className="level-item__digit title">{item.name}</div>
                            <div className="level-item__info text-start mt-2">
                              <div className="text-center mb-3 level-item__desc">
                                {item.description}
                              </div>
                              <ul className="mb-0">
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <span>Mint Price</span><br />
                                      <b className="nowrap text-white">
                                        {item["priceOfNFTInRaiseToken"]} {item["raiseTokenSymbol"]}
                                      </b>
                                    </div>
  
                                    <div className="text-end">
                                      <span>Participated</span><br />
                                      <b className="nowrap text-white">
                                        {item["totalCountUserParticipated"]}
                                      </b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <span>Mint Cap</span><br />
                                      <b className="nowrap text-white">
                                        {`${item.maxNFTsAllocated || 0}  ${item.projectTokenSymbol}`}
                                      </b>
                                    </div>
                                    <div className="text-end">
                                      <span>Raised</span><br />
                                      <b className="nowrap text-white">
                                        {item.totalFundRaised} {item.raiseTokenSymbol}
                                      </b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className={item.state == 'O' ? 'pp-card-progress-wrap disabled' : 'pp-card-progress-wrap'}>
                                    <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                      <div className="pp-card-col">Minted</div>
                                      {item.state != 'O' && <div className="pp-card-col text-end">Participants <b className="text-white">{item['totalCountUserParticipated']}</b></div>}
                                    </div>
                                    <div className='pp-card-progress'>
                                      <div title="9" className="pp-card-progress-percent" style={{ width: `${(item.totalNFTSMinted / item.maxNFTsAllocated || 0) * 100}%` }}></div>
                                      <div className="pp-card-progress-label">
                                        <span><b>{((item.totalNFTSMinted / item.maxNFTsAllocated) * 100).toFixed(2) || 0}%</b></span>
                                        {item.state == 'O' && <span className="participants-center"><b className="">{item['totalCountUserParticipated']}</b> Participants</span>}
                                        <span className="text-allocation"><b className="">{item.totalNFTSMinted}/{item.maxNFTsAllocated}</b></span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <span className="text-white fs-mb-16" style={{ fontSize: '24px' }}>
                No projects currently open
              </span>
            }
          </div>
        </div>
        {/* {openingProjects.length > 0 &&
          <div className="mt-5 text-center">
            <Link to={ROUTES.PROJECTS} className="btn btn-primary">View all Projects</Link>
          </div>
        } */}
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="py-4 mb-5 w-100" style={{ borderBottom: '1px dashed #F18A3A50', height: '1px' }}></div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default OpeningProjectsComponent;
