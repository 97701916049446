import Web3 from "web3";
import ndoAbi from "../constants/abi/ndo.json";
import {
  STAKING_CONTRACT_ADDRESS,
  NODE_URI,
  defaultChainId,
  chainIdsSupport,
  NTL_LAUNCH_PAD_CONTRACT_ADDRESS,
} from "../_configs";
import stakingABIContract from "../constants/abi/staking.json";
import erc20Abi from "../constants/abi/erc20.abi.json";
import { BigNumber } from "bignumber.js";
import _ from "lodash";
import exactMath from "exact-math";

export const getInfoContract = async (addresses) => {
  const info = {};
  const ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI.length - 1)];
  const web3 = new Web3(ethURI);
//   console.log("ethURI==>", ethURI);

  await Promise.all(
    addresses
      .filter((address) => address !== "TBA")
      .map(async (address) => {
        if (address === "TBA") {
          info[`${address}`] = {
            state: "P",
            symbol: "TBA",
            rate: 0,
            totalCountWallet: 0,
            totalCountUserParticipated: 0,
            totalFundRaised: 0,
            totalNFTMinted: 0,
            maxNFTAllocated: 0,
          };
        } else {
          const tokenContract = new web3.eth.Contract(ndoAbi, address);

          const contractInfo = await tokenContract.methods.info().call();

          const contractInfoRound = await tokenContract.methods
            .infoRounds()
            .call();

          // console.log(address + "==>",contractInfo);
          const tokenAddress = contractInfo[0];
          const raiseTokenSymbol = contractInfo[1];
          const raiseTokenDecimal = parseInt(contractInfo[2]);

          // const balance = contractInfo[3];
          const priceOfNFTInRaiseToken = exactMath.div(
            contractInfo[3],
            10 ** raiseTokenDecimal
          );
          const openTime = parseInt(contractInfo[4]);
          const fcfsOpenTime = parseInt(contractInfo[5]);
          const closeTime = parseInt(contractInfo[6]);

          const state = contractInfo[7];
          const totalCountUserParticipated = parseInt(contractInfo[8]);
          const maxNFTsAllocated = parseInt(contractInfo[9]);
          const totalNFTSMinted = parseInt(contractInfo[10]);

          const totalFundRaised = exactMath.div(
            contractInfo[11],
            10 ** raiseTokenDecimal
          );
          const nftContract = contractInfo[12];

          let infoRound = [];
          for (let i = 0; i < contractInfoRound["0"].length; i++) {
            infoRound.push({
              round: contractInfoRound[0][i],
              opens: contractInfoRound[1][i],
              closes: contractInfoRound[2][i],
            });
          }
          info[`${address}`] = {
            tokenAddress,
            raiseTokenSymbol,
            raiseTokenDecimal,
            priceOfNFTInRaiseToken,
            totalFundRaised,
            openTime,
            fcfsOpenTime,
            closeTime,
            totalCountUserParticipated,
            totalNFTSMinted,
            maxNFTsAllocated,
            state,
            // balance
            infoRound,
            nftContract,
          };
        }
      })
  );

  return info;
};

//get info from staking contract
export async function getStakingContractInfo() {
  const ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI.length - 1)];
  const web3 = new Web3(ethURI);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[defaultChainId]
  );

  try {
    const info = await stakingContract.methods.info().call();
    return {
      tokenAddr: info[0],
      tokenSymbol: info[1],
      tokenDecimals: Number(info[2]),
      stakerCount: Number(info[3]),
      totalStakedAmount: BigNumber(info[4])
        .dividedBy(10 ** 18)
        .toString(),
      apyPercentage: Number(info[5]),
      unstakePeriod: Number(info[6]),
      isStakingPaused: info[7],
      totalRewardsDistributed: BigNumber(info[8])
        .dividedBy(10 ** 18)
        .toString(),
    };
  } catch (error) {
    console.log(error);
    return {
      tokenAddr: 0,
      tokenSymbol: 0,
      tokenDecimals: 0,
      stakerCount: 0,
      totalStakedAmount: 0,
      apyPercentage: 0,
      unstakePeriod: 0,
      isStakingPaused: 0,
      totalRewardsDistributed: 0,
    };
  }
}

export const getStakeAmountWallet = async (chainId, account) => {
  const ethURI = NODE_URI[chainId][_.random(0, NODE_URI.length - 1)];

  if (!ethURI) return 0;
  const web3 = new Web3(ethURI);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[chainId]
  );

  try {
    const infoWallet = await stakingContract.methods.infoWallet(account).call();

    return new BigNumber(infoWallet[0].toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  } catch (error) {
      console.log("error=>", 0);
    return 0;
  }
};

export const getAmountPadToken = async (chainId, account) => {
  try {
    const ethURI = NODE_URI[chainId][_.random(0, NODE_URI.length - 1)];

    // console.log("ethURI eth==>", ethURI);
    if (!ethURI) return 0;

    const web3 = new Web3(ethURI);

    if (!NTL_LAUNCH_PAD_CONTRACT_ADDRESS[chainId]) return 0;

    const tokenAddress = NTL_LAUNCH_PAD_CONTRACT_ADDRESS[chainId];

    const tokenContract = new web3.eth.Contract(erc20Abi, tokenAddress);
    const tokenBalance = await tokenContract.methods.balanceOf(account).call();

    return new BigNumber(tokenBalance.toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  } catch (error) {
      console.log("error==>", error);
      return 0;
  }
};
