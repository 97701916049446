import React from "react";
import moment from 'moment'
const ScheduleTabComponent = (props) => {
  return (
    <table className="table table-schedule mb-0">
      <tbody>
        <tr className="card-header" style={{ border: "none" }}>
          <td className="text-white">Round</td>
          <td className="text-white">Opens</td>
          <td className="text-white">Closes</td>
        </tr>
        {props.roundInfo.length > 0 &&
          props.roundInfo.map((item, key) =>
          (
            <tr key={key}>
              <td className="text-white">{item.round}</td>
              <td>
                <h5 className="mb-0">{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
              </td>
              <td>
                <h5 className="mb-0">{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default ScheduleTabComponent;
