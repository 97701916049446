export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const POOL_INTERVAL = 15000;

export const EXPLORER = {
  1: "https://etherscan.io",
  4: "https://rinkeby.etherscan.io",
};
export const BACKEND_URI = {
  1: "https://app.nftlaunch.network",
  4: "https://t.nftlaunch.network",
};

export const STAKING_CONTRACT_ADDRESS = {
  1: "0x173A5c5E0106ca4F49cE91F042e32af97974035B",
  56: "0x89D92bE368eEFE1fB9d83988d78E5b8EB518C695",
};
export const NODE_URI = {
  1: [process.env.REACT_APP_NODE_INFURA_URI],
  4: ["https://rinkeby.infura.io/v3/f2473914890349138c8b03e3ef79d165"],
  56: ["https://bsc-dataseed.binance.org/"],
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const defaultChainId = process.env.REACT_APP_MODE === "TESTNET" ? 4 : 1;

export const chainIdsSupport = [4,1];

export const MINT_NFTS_URL = {
  1: "https://api.opensea.io/api/v1/asset",
  4: "https://testnets-api.opensea.io/api/v1/asset",
};

export const OPEN_SEA_NFT_URL = {
  1: "https://opensea.io/assets",
  4: "https://testnets.opensea.io/assets",
};
export const IPFS_GATE_WAY = "https://nftlaunch.mypinata.cloud/ipfs/";

// https://ipfs.io/ipfs/QmXBK7oj7hW6b13WZsNdh29V6SPjnvLr71z56ARxsLQvRx/unknown.gif

export const AMOUNT_ENABLE_KYC = 1000.0;

export const NTL_LAUNCH_PAD_CONTRACT_ADDRESS = {
  1: "0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD",
  56: "0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD",
};


console.log("MODE==>",  process.env.REACT_APP_MODE);