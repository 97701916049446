export const ACTION_STATUS = {

    APPROVING: "APPROVING",
    APPROVED:"APPROVED",
    APPROVE_FAILS:"APPROVE_FAILS",
    CLAIM_FAIL: "CLAIM_FAIL",
    CLAIM_SUCCESS:"CLAIM_SUCCESS",
    PARTICIPATE_SUCCESS :"PARTICIPATE_SUCCESS",
    PARTICIPATE_FAIL:"PARTICIPATE_FAIL",
    ESTIMATE_GAS_FAILS:'ESTIMATE_GAS_FAILS',
}