import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";
import exactMath from 'exact-math';
import { useActiveWeb3React } from "../../../hook";
import { getAmountPadToken, getStakeAmountWallet } from "../../../utils/contractHelpers";
import { useKycStatus, useLatestBlockNumber, useShowModalHelp, useWeb3Utils } from "../../../hook/useState";
import { AMOUNT_ENABLE_KYC } from "../../../_configs";

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const showModalHelp = useShowModalHelp();
  const latestBlock = useLatestBlockNumber();
  // const web3Utils = useWeb3Utils();
  const [enableKYC, setEnableKYC] = useState(false);

  const kycStatus = useKycStatus();
  const [nftlPadBalance, setNFTlPadBalance] = useState(0);

  const [bscStaked, setBSCStaked] = useState(0);
  const [bscPadBalance, setBSCPadBalance] = useState(0);
  const [ethStaked, setETHStaked] = useState(0);
  const [ethPadBalance, setETHPadBalance] = useState(0);

  //get info account
  useEffect(() => {
    if (account) {
      getStakeAmountWallet(56, account).then(result => {
        // console.log("staked bsc==>", result);
        setBSCStaked(result)
      })
      getStakeAmountWallet(1, account).then(result => {
        // console.log("staked eth==>", result);
        setETHStaked(result);
      })

      getAmountPadToken(56, account).then(balance => {
        // console.log("balance bsc==>", balance);
        if (Number(chainId) === 56) {
          setNFTlPadBalance(balance)
        }
        setBSCPadBalance(balance)
        // setNFTlPadBalance(balance)
      })
      getAmountPadToken(1, account).then(balance => {
        // console.log("balance eth==>", balance);
        setETHPadBalance(balance)
        if (Number(chainId) === 1) {
          setNFTlPadBalance(balance)
        }
        setETHPadBalance(balance)
      })

    }



  }, [account, latestBlock])






  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscStaked) + Number(bscPadBalance) + Number(ethStaked) + Number(ethPadBalance)) >= AMOUNT_ENABLE_KYC) {

      setEnableKYC(true)

      getKYCAddress(account)

    } else {
      setEnableKYC(false)
    }

  }, [chainId, account, latestBlock])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center p-0" href="https://nftlaunch.network/" target="_blank">
            <img src="/images/logo_w.png" height="45" alt="NFTLaunchPad" className="me-2" />
          </a>
          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-3 mb-lg-0 flex-row mt-3 mt-lg-0">
            <li className="nav-item me-2">
                <NavLink className="nav-link active" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <span>NFTs</span>
                </NavLink>
              </li>
              <li className="nav-item me-2">
              <a href={`https://app-ido.nftlaunch.network/projects`} target="blank" aria-current="page" className="nav-link">
                  <span>Projects</span>
                </a>
              </li>
              <li className="nav-item me-2">
                <a href={`https://staking.nftlaunch.network/`} target="blank" aria-current="page" className="nav-link">
                  <span>Staking</span>
                </a>
              </li>

              {/* <li className="nav-item me-2">
                <a href={`https://bridge-nftlaunch.network`} target="blank" aria-current="page" className="nav-link">
                  <span>Bridge</span>
                </a>
              </li> */}
            </ul>
            <ul className={account ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>

              {
                !account ?
                  <li className="nav-item me-2 connect-wallet-button">
                    <a className="nav-link mybtn1 w-mb-100 px-5 text-center" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>Connect Wallet</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2 w-mb-100 d-flex">
                      <a className="nav-link mybtn1 btn-balance w-mb-100 px-5 text-center" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(account, 4, 4)}</span> - <b>{helpers.formatNumberDownRoundWithExtractMax(nftlPadBalance, 4)}</b> NFTL
                      </a>
                      {/* <span className="network-connected">ETH</span> */}
                    </li>
                    {
                      kycStatus === 'START' &&
                      <li className="nav-item me-2">
                        <button className="nav-link mybtn2 btn-kyc btn-kyc-start"
                          onClick={() => handleOnclickKyc()}
                          id="bnt-kyc-start">
                          <i className="mdi mdi-pencil-plus me-1"></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item me-2">
                        <button className="nav-link mybtn1 btn-kyc btn-kyc-pending"
                          onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-exclamation-thick me-1"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item me-2">
                        <button className="nav-link mybtn1 btn-kyc btn-kyc-success"
                          onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-check-bold me-1"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item me-2">
                        <button className="nav-linknav-link mybtn1 btn-kyc btn-kyc-danger"
                          onClick={() => handleOnclickKyc()}
                        >
                          <i className="mdi mdi-alert-octagon-outline me-1"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
