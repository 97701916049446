import { ACTION_CONST } from "../../constants";

const INITIAL_STATE = {
    walletInfo: {},
    kycStatus: null,
    kycURL: "",
    jobKyc: 0,
    stakingWalletInfo: {},
    jobUnStakeTime: 0,
    nftlBalance: 0,
    ethBalance: 0,
    jobStakingStatus: 0,
    selectedImage: null,
    showNFTModal: false
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_CONST.GET_NDO_INFO_WALLET:
            return {
                ...state,
                walletInfo: action.data,
            };
        case ACTION_CONST.GET_KYC_INFO:
            return {
                ...state,
                kycStatus: action.data,
            };

        case ACTION_CONST.SET_JOB_GET_KYC:
            clearInterval(state.jobKyc);
            return {
                ...state,
                jobKyc: action.data,
            };

        case ACTION_CONST.GET_STAKING_WALLET_INFO:
            return {
                ...state,
                stakingWalletInfo: action.data,
            };

        case ACTION_CONST.GET_NFTL_BALANCE:
            return {
                ...state,
                nftlBalance: action.data,
            };

        case ACTION_CONST.GET_BNB_BALANCE:
            return {
                ...state,
                ethBalance: action.data,
            };

        case ACTION_CONST.SET_JOB_GET_STAKING_STATUS:
            clearInterval(state.jobStakingStatus);
            return {
                ...state,
                jobStakingStatus: action.data,
            };
        case ACTION_CONST.SET_IMAGES_SELECTED:
            return {
                ...state,
                selectedImage: action.data,
            };
        case ACTION_CONST.SHOW_NFT_MODAL:
            return {
                ...state,
                showNFTModal: true,
            };
        case ACTION_CONST.HIDE_NFT_MODAL:
            return {
                ...state,
                showNFTModal: false,
            };
        default:
            return state;
    }
};