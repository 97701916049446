export const ACTION_CONST = {
    LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
    REQUEST_SUBMIT_BLOCKING_UI: 'REQUEST_SUBMIT_BLOCKING_UI',
    REQUEST_DONE_BLOCKING_UI: 'REQUEST_DONE_BLOCKING_UI',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_FAILS: 'ALERT_FAILS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_LINK: 'ALERT_LINK',
    ALERT_LINK_CLEAR: 'ALERT_LINK_CLEAR',

    CONNECT_WALLET_SUCCESS: 'CONNECT_WALLET_SUCCESS',
    LOG_OUT_WALLET_SUCCESS: 'LOG_OUT_WALLET_SUCCESS',
    ENABLE_WALLET_SUCCESS: 'ENABLE_WALLET_SUCCESS',

    SET_SHOW_MODAL_HELP: 'SET_SHOW_MODAL_HELP',





    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_LIST_CONTRACTS_INFO: 'GET_LIST_CONTRACTS_INFO',
    GET_PROJECT_SELECTED: 'GET_PROJECT_SELECTED',
    SUBMIT_GET_PROJECTS: 'SUBMIT_GET_PROJECTS',



    SUBMIT_PROJECT_SELECTED: 'SUBMIT_PROJECT_SELECTED',
    GET_PROJECT_SELECTED_SUCCESS: 'GET_PROJECT_SELECTED_SUCCESS',

    SET_CURRENT_CONTRACT_SELECTED: 'SET_CURRENT_CONTRACT_SELECTED',

    SET_LIST_CONTRACT_SELECTED: 'SET_LIST_CONTRACT_SELECTED',


    SET_JOB_PROJECT_SELECTED: 'SET_JOB_PROJECT_SELECTED',
    SET_JOB_GET_WALLET_INFO: 'SET_JOB_GET_WALLET_INFO',
    SET_JOB_COUNT_DOWN_OPEN: 'SET_JOB_COUNT_DOWN_OPEN',
    SET_JOB_COUNT_DOWN_CLOSE: 'SET_JOB_COUNT_DOWN_CLOSE',
    SET_JOB_COUNT_DOWN_ROUND: 'SET_JOB_COUNT_DOWN_ROUND',
    SET_JOB_COUNT_DOWN_FCFS_TIME: 'SET_JOB_COUNT_DOWN_FCFS_TIME',

    SET_JOB_COUNT_DOWN_LAUNCH_TIME: 'SET_JOB_COUNT_DOWN_LAUNCH_TIME',

    SET_JOB_GET_KYC: 'SET_JOB_GET_KYC',
    SET_JOB_GET_PROJECTS: 'SET_JOB_GET_PROJECTS',

    CLEAR_INTERVAL_PROJECTS_JOB: 'CLEAR_INTERVAL_PROJECTS_JOB',
    GET_NDO_INFO_WALLET: 'GET_NDO_INFO_WALLET',

    GET_KYC_INFO: 'GET_KYC_INFO',



    //merge staking
    SET_JOB_GET_BALANCE: 'SET_JOB_GET_BALANCE',
    GET_STAKING_INFO: 'GET_STAKING_INFO',
    SET_JOB_GET_STAKING_INFO: 'SET_GET_STAKING_INFO',
    GET_STAKING_WALLET_INFO: 'GET_STAKING_WALLET_INFO',



    SUBMIT_GET_BALANCE: 'SUBMIT_GET_BALANCE',
    GET_NFTL_BALANCE: 'GET_NFTL_BALANCE',
    GET_BNB_BALANCE: 'GET_BNB_BALANCE',
    SET_JOB_GET_BALANCE: 'SET_JOB_GET_BALANCE',


    CLEAR_STAKING_JOB: 'CLEAR_STAKING_JOB',

    SET_STAKING_ENABLE: 'SET_STAKING_ENABLE',
    SET_JOB_GET_STAKING_STATUS: 'SET_JOB_GET_STAKING_STATUS',

    SET_LATEST_BLOCK: "SET_LATEST_BLOCK",

    SET_IMAGES_SELECTED: 'SET_IMAGES_SELECTED',
    SHOW_NFT_MODAL: 'SHOW_NFT_MODAL',
    HIDE_NFT_MODAL: 'HIDE_NFT_MODAL',

    SET_JOB_SET_TRADE_ABLE:'SET_JOB_SET_TRADE_ABLE',

    SET_JOB_GET_DATA_OPEN_SEA: 'SET_JOB_GET_DATA_OPEN_SEA',
    SET_JOB_GET_OPEN_SEA_API:'SET_JOB_GET_OPEN_SEA_API',
    CLEAR_JOB_GET_OPEN_SEA_API:'CLEAR_JOB_GET_OPEN_SEA_API',
    SET_JOB_GET_META_DATA: 'SET_JOB_GET_META_DATA',
    CLEAR_JOB_GET_META_DATA:'CLEAR_JOB_GET_META_DATA',



    SET_MESSAGE :'SET_MESSAGE',
    SET_SHOW_TRANSACTION_HASH_MODAL:'SET_SHOW_TRANSACTION_HASH_MODAL',


    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_FAIL: 'REQUEST_FAIL',

    SHOW_MODAL_SUBMIT:'SHOW_MODAL_SUBMIT',
    HIDE_MODAL_SUBMIT: 'HIDE_MODAL_SUBMIT'

    
}