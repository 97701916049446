import { ACTION_CONST } from "../../constants";

export function actGetListProjects() {
    return {
        type: ACTION_CONST.SUBMIT_GET_PROJECTS,

    }
}
export function actSelectedProject(contract) {
    return {
        type: ACTION_CONST.SUBMIT_PROJECT_SELECTED,
        data: contract

    }
}

export function actSetCurrentContract(contract) {
    return {
        type: ACTION_CONST.SET_CURRENT_CONTRACT_SELECTED,
        data: contract
    }
}

export function actGetWalletInfo(wallet) {
    return {
        type: ACTION_CONST.GET_NDO_INFO_WALLET,
        data: wallet
    }
}

export function actShowNFTModal() {
    return {
        type: ACTION_CONST.SHOW_NFT_MODAL
    }
}
export function actHideNFTModal() {
    return {
        type: ACTION_CONST.HIDE_NFT_MODAL
    }
}