import React, { useEffect, useState } from "react";
import moment from 'moment'

export const rendererOpening = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      
      return <span><b>0h 0m 0s</b></span>;
    } else {
      // Render a countdown
      return <span className="pp-status-opening"> Opens in {hours}h {minutes}m {seconds}s</span>;
    }
  };

  
  export const renderCountDownRoundTime = ( {hours, minutes, seconds, completed })=>{
    if (completed) {
      // Render a completed state
      return <h4>0h 0m 0s</h4>;
    } else {
      // Render a countdown
      return <h4>{hours}h {minutes}m {seconds}s</h4>;
    }
  }


  export const renderCountDownFCFSTime = ( {hours, minutes, seconds, completed })=>{
    if (completed) {
      // Render a completed state
      return <h4>0h 0m 0s</h4>;
    } else {
      // Render a countdown
      return <h4>{hours}h {minutes}m {seconds}s</h4>;
    }
  }


  export const renderCountDownCloseTime = ( {days, hours, minutes, seconds, completed })=>{
    if (completed) {
      // Render a completed state
      return <h4>0h 0m 0s</h4>;
    } else {
      // Render a countdown
      return <h4>{days}d {hours}h {minutes}m {seconds}s</h4>;
    }
  }

  export const renderCountLaunchTime  = ({hours, minutes, seconds, completed , props}) =>{
    if (completed) {
      // Render a completed state
      return <h2 className="title mb-0 fs-40">Launched at {moment(props.date).utc().format('MM-DD HH:mm')} UTC</h2>;
    } else {
      // Render a countdown
      return <h2 className="title mb-0 fs-40">Launching in {hours}h {minutes}m {seconds}s</h2>;
  }
}