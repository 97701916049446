import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelectedImageMint } from '../../hook/useState';
import { ACTION_CONST } from "../../constants";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { actHideNFTModal } from '../../redux/action/user';
import { useEffect } from 'react';
import { IPFS_GATE_WAY } from '../../_configs';

const NFTDetailModal = () => {
    const dispatch = useDispatch();
    const selectedImage = useSelectedImageMint();
    let attributes = get(selectedImage, "attributes", [])
    const unknownState = get(selectedImage, "unknown", false);
    let metaDataLink = get(selectedImage, "metaDataLink", "");
    let name = get(selectedImage, "name", "");
    let permalink = get(selectedImage, "permalink", "");
    let image = get(selectedImage, "image", "");
    let description = get(selectedImage, "description", "");
    const showModal = useSelector(state => state.wallet.showNFTModal);
    const error = get(selectedImage, "error", false);
    let openSeaApiURI = get(selectedImage, "openSeaApiURI", null);
    let tokenId = get(selectedImage, "tokenId", null);



    const handleDismissModal = () => {
        dispatch(actHideNFTModal());
    }


    //neu error thi goi api opensea, con neu unknown thi goi api token metadata

    useEffect(() => {
        //neu error thi call api opensea 30s 
        // console.log("error opensea==>", error);
        if (error) {

            const job = setInterval(async () => {
                const timeStamp = new Date().getTime();
                let unknown = true;
                try {

                    const result = await axios.get(openSeaApiURI);

                    permalink = result.data.permalink;
                    attributes = result.data.traits;

                    if (result.status === 200 && result.data) {
                        metaDataLink = result.data.token_metadata

                        //try catch get meta data
                        try {
                            const metaDataRes = await axios.get(metaDataLink + `?ts=${timeStamp}`);
                            if (metaDataRes.status === 200 && metaDataRes.data.unknown !== true) {
                                unknown = metaDataRes.data.unknown
                                image = metaDataRes.data.image.replace("ipfs://", IPFS_GATE_WAY);
                                description = metaDataRes.data.description
                                // attributes = metaDataRes.data.attributes
                                // console.log("update description ==>",description );
                                // console.log("update image ==>",image );
                                if (!unknown) {
                                    dispatch({
                                        type: ACTION_CONST.SET_IMAGES_SELECTED,
                                        data: { image, description, tokenId, attributes, name, permalink, unknown, metaDataLink, openSeaApiURI, error: false }
                                    })
                                }
                            }
                        } catch (error) {
                            console.log("error get meta data==>", error);
                        }
                        dispatch({ type: ACTION_CONST.CLEAR_JOB_GET_OPEN_SEA_API })
                    }


                } catch (error) {
                    console.log("error==>", error);
                }

                // call open sea api

            }, 15 * 1000)

            dispatch({ type: ACTION_CONST.SET_JOB_GET_OPEN_SEA_API, data: job })
        } else {
            dispatch({ type: ACTION_CONST.CLEAR_JOB_GET_OPEN_SEA_API })
        }

    }, [error])

    useEffect(() => {

        // console.log("unknownState==>", unknownState);
        if (unknownState || attributes.length === 0 && selectedImage) {

            const job = setInterval(async () => {
                const timeStamp = new Date().getTime();
                let unknown = true;
                try {


                    try {
                        const metaDataRes = await axios.get(metaDataLink + `?ts=${timeStamp}`);
                        if (metaDataRes.status === 200 && metaDataRes.data.unknown !== true) {
                            unknown = metaDataRes.data.unknown;
                            description = metaDataRes.data.description
                            attributes = metaDataRes.data.attributes
                            image = metaDataRes.data.image.replace("ipfs://", IPFS_GATE_WAY);
                           
                            // console.log("update description ==>", description);
                            console.log("update image ==>", image);
                            if (!unknown) {
                                dispatch({
                                    type: ACTION_CONST.SET_IMAGES_SELECTED,
                                    data: { image, description, tokenId, attributes, name, permalink, unknown, metaDataLink, openSeaApiURI }
                                })
                            }
                        }
                    } catch (error) {
                        console.log("error get meta data==>", error);
                    }



                } catch (error) {
                    console.log("error==>", error);
                }

                // call open sea api

            }, 15 * 1000)

            dispatch({ type: ACTION_CONST.SET_JOB_GET_META_DATA, data: job })
        } else {
            dispatch({ type: ACTION_CONST.CLEAR_JOB_GET_META_DATA })
        }
    }, [unknownState])


    // console.log("selectedImage==>", selectedImage);
    return (
        <>
            <Modal show={showModal} onHide={handleDismissModal} size="xl" centered={true} id="nftDetailModal" tabIndex="-1" aria-labelledby="nftDetailModalLabel" aria-hidden="true">
                <Modal.Header closeButton>
                    <h6 className="modal-title" id="nftDetailModalLabel">{get(selectedImage, 'name', "")}</h6>
                </Modal.Header>
                <Modal.Body>
                    {!error ?
                        <div className="row gx-xl-5">
                            <div className="col-lg-6">
                                <div className="level-item bordered project-item mb-4 mt-xl-3">
                                    <div className="bordered__inner bordered--double_angled bordered--bg_top">
                                        <div className="level__inner pb-0 pt-3">
                                            <div className="level-item__img mb-3">
                                                <img src={get(selectedImage, 'image', "")} alt="nft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-3 text-center">
                                    <a href={get(selectedImage, 'permalink', "#")} target="_blank" title="OpenSea" className="mybtn2">
                                        <img height="32" src="/images/opensea.svg" />
                                        &nbsp;OpenSea
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="nft-desc">{get(selectedImage, 'description', "")}</div>
                                <div className="mt-4">
                                    {(!unknownState && attributes.length > 0) ?
                                        <>
                                            <p>Properties</p>
                                            <div className="row">

                                                {
                                                    attributes.map((a, k) => {
                                                        if (a.trait_type !== "ID") {
                                                            return <div key={k} className="col-md-6" >
                                                                <div className="s-info" >
                                                                    <div className="content" key={k}>
                                                                        <p>{a.trait_type}</p>
                                                                        <h4>{a.value}</h4>
                                                                        {/* <div>16% have this trait</div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <div className="modal-notice">
                                            <div className="mt-3 mb-4">
                                                <span>Unable to verify metadata refresh on OpenSea.</span>
                                            </div>
                                            <div className="confirm-icon">
                                                <img className="spinner" src="/images/loader.svg" alt="loader" width="80" height="80" />
                                            </div>

                                            <div className="mt-3 mb-4">
                                                <span>NFT properties will update here when metadata is refreshed.</span>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row gx-xl-5">
                            <div className="col-lg-6">
                                <div className="level-item bordered project-item mb-4 mt-xl-3">
                                    <div className="bordered__inner bordered--double_angled bordered--bg_top">
                                        <div className="level__inner pb-0 pt-3">
                                            <div className="level-item__img mb-3">
                                                <img src={get(selectedImage, 'image', "")} alt="nft" />
                                            </div>
                                        </div>
                                        <div className="my-3 text-center">
                                            <a href={get(selectedImage, 'permalink', "#")} target="_blank" title="OpenSea" className="mybtn2">
                                                <img height="32" src="/images/opensea.svg" />
                                                &nbsp;OpenSea
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="nft-desc">{get(selectedImage, 'description', "")}</div>
                                <div className="mt-4">


                                    <div className="modal-notice">
                                        <div className="mt-3 mb-4">
                                            <span>Unable to verify metadata refresh on OpenSea.</span>
                                        </div>
                                        <div className="confirm-icon">
                                            <img className="spinner" src="/images/loader.svg" alt="loader" width="80" height="80" />
                                        </div>

                                        <div className="mt-3 mb-4">
                                            <span>NFT properties will update here when metadata is refreshed.</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {/*  */}
                </Modal.Body>
            </Modal>
        </>
    );
}
export default NFTDetailModal;


