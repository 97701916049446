import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
const ClickwrapAcceptancePage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start active" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link>
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
            <>
                <p className="mb-2" style={{ textAlign: 'center' }}><b>BSCP DIGITAL LIMITED</b></p>
                <p className="mb-2" style={{ textAlign: 'center' }}><b>Clickwrap Acceptance of <a href="https://nftlaunch.network/">https://nftlaunch.network/</a></b></p>
                <p style={{ textAlign: 'center' }}><b>Last revised Date 7</b><b>th</b><b> May 2021</b></p>
                <h1>Clickwrap Acceptance</h1>
                <p><span style={{ fontWeight: 400 }}>By clicking on the “I Agree”</span> <span style={{ fontWeight: 400 }}>button below, I acknowledge that I have read the <Link to={ROUTES.TERMS_OF_USE}>[Terms and Conditions]</Link> and <Link to={ROUTES.PRIVACY_POLICY}>[Privacy Policy]</Link>, and that I agree to their terms. I understand that the Privacy Policy contain Cookie Policy.&nbsp;</span></p>
                <p><span style={{ fontWeight: 400 }}>We use cookies to personalise content and to provide you with an improved user experience. By continuing to browse this site you consent to the use of cookies. You can find out more in our privacy policy and <Link to={ROUTES.COOKIE_POLICY}>Cookie Policy</Link>, and manage the choices available to you at any time by following the instruction of your device preferences.</span></p>
                <p><span style={{ fontWeight: 400 }}>[&nbsp; ] I AGREE, and continue with the Service</span></p>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClickwrapAcceptancePage;
