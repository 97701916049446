import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { useLatestBlockNumber, useSelectedProject } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import { IPFS_GATE_WAY, MINT_NFTS_URL, OPEN_SEA_NFT_URL } from "../../_configs";
import { get, truncate } from "lodash";
import { ACTION_CONST } from "../../constants";
import { actShowNFTModal } from "../../redux/action/user";

const YourAllocationComponent = ({ allocationInfo, launchTime }) => {
    // console.log("allocationInfo==>", allocationInfo);
    const latestBlock = useLatestBlockNumber();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { chainId } = useActiveWeb3React();
    const selectedProject = useSelectedProject();
    const [tradeAble, setTradAble] = useState(false)
    const nftContract = get(selectedProject, "nftContract", "");


    useEffect(() => {
        if (allocationInfo.length > 0) {

            getMetaDataOfImage(allocationInfo).then(result => {
                // console.log("result==>", result);
                setData(result)
            })
        } else {
            setData([])
        }

    }, [allocationInfo, latestBlock])

    //check tradeAble Item
    useEffect(() => {

        const timeDelay = 2 * 1000
        if (new Date().getTime() >= (launchTime + timeDelay)) {
            setTradAble(true)
        } else {
            setTradAble(false)
        }
        const job = setInterval(() => {
            const timeStamp = new Date().getTime()
            if (timeStamp >= (launchTime + timeDelay)) {
                setTradAble(true)
            } else {
                setTradAble(false)
            }
            // console.log("check TradeAble==>", timeStamp  >= launchTime);
        }, 1500);


        dispatch({ type: ACTION_CONST.SET_JOB_SET_TRADE_ABLE, data: job })

    }, [launchTime])



    const getMetaDataOfImage = async (data) => {

        const timeStamp = new Date().getTime()
        let temp = []
        for (const item of data) {
            if (item.url) {

                let urlMetadata = item.url

                const data = await axios.get(urlMetadata + `?ts=${timeStamp}`)

                if (data.status === 200) {

                    const name = data.data.name;
                    const attributes = data.data.attributes;
                    const image = data.data.image.replace("ipfs://", IPFS_GATE_WAY);
                    const description = data.data.description;

                    // let openSeaApiURI = "";



                    const openSeaApiURI = `${MINT_NFTS_URL[chainId]}/${nftContract}/${item.no}` + `/?force_update=true`;


                    temp.push({ name, attributes, image, description, id: item.no, openSeaApiURI, metaDataApiUri: urlMetadata }
                    )
                }
            }
        }
        //    console.log(temp);
        return temp

    }



    const handleClickNft = async (item) => {


        let { image, attributes, name, description, openSeaApiURI } = item;

        if(!openSeaApiURI) return;

        const timeStamp = new Date().getTime()

        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT_BLOCKING_UI
        })



        let tokenId = item.id;
        let metaDataLink = item.metaDataApiUri;
        let permalink = `${OPEN_SEA_NFT_URL[chainId]}/${nftContract}/${tokenId}`;
        let unknown = true;
        // "https://testnets.opensea.io/assets/0xa68e3d85f82bd72a765f9d965106770cd33b03a1/92"
        //try catch get opensea api
        try {
            if (openSeaApiURI) {
                const result = await axios.get(openSeaApiURI);

                if (result.status === 200 && result.data) {

                    permalink = result.data.permalink;

                    name = result.data.name;
                    metaDataLink = result.data.token_metadata
                    attributes = result.data.traits
                    //try catch get meta data
                    try {
                        const metaDataRes = await axios.get(metaDataLink + `?ts=${timeStamp}`);
                        if (metaDataRes.status === 200 && metaDataRes.data.unknown !== true) {
                            tokenId = result.data.token_id;
                            description = metaDataRes.data.description
                            unknown = metaDataRes.data.unknown;
                            // attributes = metaDataRes.data.attributes;

                            image = metaDataRes.data.image.replace("ipfs://", IPFS_GATE_WAY);;
                        }
                    } catch (error) {
                        console.log("error get meta data==>", error);
                    }
                }
            }

            //set image pop up show 
            dispatch({
                type: ACTION_CONST.SET_IMAGES_SELECTED,
                data: { image, description, tokenId, attributes, name, permalink, unknown, metaDataLink, openSeaApiURI, error: false }
            })


        } catch (error) {
            console.log("error get opensea link==>", error);


            //set image pop up show 
            dispatch({
                type: ACTION_CONST.SET_IMAGES_SELECTED,
                data: { image, description, tokenId, attributes, name, openSeaApiURI, permalink, error: true }
            })
        }

        dispatch({
            type: ACTION_CONST.REQUEST_DONE_BLOCKING_UI
        })


        dispatch(actShowNFTModal());


    }


    return (
        <div className="">
            {data.length > 0 ?
                <div className="row gx-xl-5 mb-3">
                    {data.map((item, key) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 text-center" key={key}>
                            <div className="level-item level-item-light bordered project-item mb-4">
                                <div className="level-item-top">
                                    {
                                        tradeAble ?
                                            <span className="pp-status pp-status-open">Tradeable</span> :
                                            <span className="pp-status pp-status-disabled">LAUNCHING SOON</span>
                                    }
                                </div>
                                <div className="bordered__inner bordered--double_angled bordered--bg_top">
                                    <div className="level__inner pb-0">
                                        {
                                            tradeAble ?
                                                <div className="text-decoration-none d-block" onClick={() => handleClickNft(item)} >
                                                    <div className="level-item__img mb-2">
                                                        <img src={item.image} alt="nft" style={{ borderRadius: 0 }} />
                                                    </div>
                                                    <div className="level-item__digit title">{item.name}</div>
                                                    <div className="level-item__info text-start">
                                                        <div className="text-center mb-0 level-item__desc">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="text-decoration-none d-block" >
                                                    <div className="level-item__img mb-2">
                                                        <img src={item.image} alt="nft" style={{ borderRadius: 0 }} />
                                                    </div>
                                                    <div className="level-item__digit title">{item.name}</div>
                                                    <div className="level-item__info text-start">
                                                        <div className="text-center mb-0 level-item__desc">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> :
                <div className="text-center">No Nfts currently</div>
            }
        </div>
    );
};

export default YourAllocationComponent;


