import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function OHTTooltip({ children, tooltip }) {
    const renderTooltip = (props) => (
        tooltip ? <Tooltip {...props}>
            {tooltip}
        </Tooltip> : <></>
    );

    return (
        <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
        >
            {children}
        </OverlayTrigger>
    )
}
export default OHTTooltip;