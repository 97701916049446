import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { history } from "../../utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import moment from 'moment'
import InvestmentModal from "./InvestModal";
import { actSelectedProject, } from "../../redux/action/user";
import { formatEtherDownRound, helpers } from "../../utils/helpers";
import ScheduleTabComponent from "./ScheduleTabComponent";
import YourAllocationComponent from "./YourAllocationComponent";
import { useParams } from "react-router";
import { useBlockingUI, useLatestBlockNumber, useNdoWalletInfo, useProjectTokenRaiseSymbol, useSelectedProject, useShowModalHelp, useStatuRequest } from "../../hook/useState";
import { getNdoAllocation, getNdoWalletInfo } from "../../utils/ndoContractHelper";
import Countdown from 'react-countdown';
import { useActiveWeb3React } from "../../hook";
import { renderCountDownCloseTime, renderCountDownRoundTime, rendererOpening } from "./hook";
import { getCountDown, getCountDownLaunchTime } from "../../utils/helper";


const ProjectDetailPage = (props) => {
  const dispatch = useDispatch();
  const { account, library } = useActiveWeb3React();

  let params = useParams();

  const [contractAddress, setContractAddress] = useState("");
  const [nftLaunchTime, setNftLaunchTime] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);

  const [allocationInfo, setAllocationInfo] = useState([])
  const ndoInfoWallet = useNdoWalletInfo();
  const latestBlock = useLatestBlockNumber();

  const selectedProject = useSelectedProject()

  const showModalHelp = useShowModalHelp();

  const statusRequest = useStatuRequest();


  const tokenRaiseSymbol = useProjectTokenRaiseSymbol();
  const projectState = get(selectedProject, "state", "");
  const projectTokenSymbol = get(selectedProject, "projectTokenSymbol", "TBA");

  //projectInfo
  const mintCap = get(selectedProject, "maxNFTsAllocated", 0);
  const mintPrice = get(selectedProject, "priceOfNFTInRaiseToken", 0);
  const projectRaiseTokenDecimals = get(selectedProject, "raiseTokenDecimal", 18);
  const totalFundRaised = get(selectedProject, "totalFundRaised", 0);
  const openTime = get(selectedProject, "openTime", 0);
  const closeTime = get(selectedProject, "closeTime", 0);
  const fcfsOpenTime = get(selectedProject, "fcfsOpenTime", 0);
  const yourAllocationVisible = get(selectedProject, "yourAllocationVisible", false);
  const totalNftsMinted = get(selectedProject, "totalNFTSMinted", 0);
  // const nftLaunchTime = get(selectedProject, "launchTime", null);

  const roundInfo = get(selectedProject, "infoRound", []);

  //walletInfo
  // const ethBalance = get(ndoInfoWallet, "nativeBalance", 0);
  const tokenRaiseBalance = get(ndoInfoWallet, "raiseTokenBalance", 0);
  const tier = get(ndoInfoWallet, "tier", "-");
  const userParticipation = get(ndoInfoWallet, "userParticipation", 0);
  const remainingAllocation = get(ndoInfoWallet, "remainingAllocation", 0);
  const roundState = get(ndoInfoWallet, "roundState", 0);

  const roundStateText = get(ndoInfoWallet, "roundStateText", "");
  const roundTime = get(ndoInfoWallet, "roundTimestamp", 0);




  useEffect(() => {
    const { contract } = params

    if (contract && typeof contract === "string") {

      setContractAddress(contract);
      // dispatch(actSelectedProject(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [])


  //Job interval get contract info
  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }

  }, [latestBlock, contractAddress, statusRequest]);


  useEffect(() => {
    if (contractAddress && library && account) {
      //get wallet Ndo Info
      getNdoWalletInfo(library.provider, contractAddress, account).then(info => {
        dispatch({
          type: ACTION_CONST.GET_NDO_INFO_WALLET,
          data: info
        })
      })

      //get Ndo Allocation 
      getNdoAllocation(library.provider, contractAddress, account).then(data => {
        // console.log("allocation==>",data);
        setAllocationInfo(data.infoAllocation)
        setNftLaunchTime(data.launchTime)
      })


    }

  }, [contractAddress, library, account, latestBlock, statusRequest])



  // console.log("projectState.state", projectState);
  //check enable JoinProject
  useEffect(() => {
    if (!(roundState === 1 || roundState === 3)) {
      setEnableJoinProject(false);
      return;
    }

    if (projectState === "C" || projectState === "P") {
      setEnableJoinProject(false)
      return;
    }
    else {

      if (remainingAllocation === 0) {
        setEnableJoinProject(false)
        return;
      } else {
        setEnableJoinProject(true)
      }

    }
  }, [projectState, remainingAllocation])


  //set count down launchTime
  useEffect(()=>{
    if(nftLaunchTime> 0){
      getCountDownLaunchTime(`idLaunchTime`, nftLaunchTime * 1000, function start(job) {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_LAUNCH_TIME,
          data: job
        })
      }, function end(job) {
        getNdoAllocation(library.provider, contractAddress, account).then(data => {
          setAllocationInfo(data.infoAllocation)
        })
      })
    }
   
  },[nftLaunchTime])

  //set countdown round Time
  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && contractAddress) {
      getCountDown(`idRoundTime-${contractAddress}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {
        //do smt when countdown expired
        getNdoWalletInfo(library.provider, contractAddress, account).then(info => {
          dispatch({
            type: ACTION_CONST.GET_NDO_INFO_WALLET,
            data: info
          })
        })
      });
    }

  }, [roundTime, contractAddress])
  //set countdown round Time
  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && contractAddress) {
      getCountDown(`idFcfsOpenTime-${contractAddress}`, fcfsOpenTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })

      }, function end(job) {
        //do smt when countdown expired
        getNdoWalletInfo(library.provider, contractAddress, account).then(info => {
          dispatch({
            type: ACTION_CONST.GET_NDO_INFO_WALLET,
            data: info
          })
        })
      });
    }

  }, [fcfsOpenTime, contractAddress])




  return (
    <>
      {selectedProject ?
        <div className="pp-detail-page mb-5" style={{ paddingTop: '150px' }}>
          <div className="container">
            <div className="single-turnaments">
              <div className="left-area">
                <div className="single-play">
                  <div className="items-morex">
                    {/* <div><span className="">{tokenRaiseSymbol}</span></div> */}
                    {(projectState === "O" || projectState === "F") && <span className="pp-status-open"><i className="mdi mdi-circle"></i> Open</span>}
                    {projectState === "C" && <span className="pp-status-closed"><i className="mdi mdi-circle"></i> Closed</span>}
                    {projectState === "P" &&
                      <>
                        {
                          openTime !== undefined ?
                            <Countdown
                              date={new Date(openTime * 1000)}
                              intervalDelay={1}
                              precision={3}
                              renderer={rendererOpening}
                            />
                            // <span className="pp-status-opening"><i className="mdi mdi-circle"></i> Opens in <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                            : <span className="pp-status-opening"><i className="mdi mdi-circle"></i>TBA</span>
                        }
                      </>
                    }
                  </div>
                  <div className="image">
                    <img src={get(selectedProject, "logo", "")} alt={get(selectedProject, "name", "")} className="w-100" />
                  </div>
                  <div className="contant">
                    {!account ?
                      <button className="mybtn2 no-bg" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"} style={{ fontSize: '16px' }}>
                        <span>Connect Wallet</span>
                      </button>
                      :
                      <button className="mybtn2 no-bg" data-bs-toggle="modal" data-bs-target={enableJoinProject ? "#buyModal" : ""} disabled={!enableJoinProject} style={{ fontSize: '16px' }}>
                        <span>Mint</span>
                      </button>
                    }
                  </div>
                  <div className="p-detail-social">
                    {
                      get(selectedProject, "pancakeswap", "") &&
                      (<a href={get(selectedProject, "logo", "")} target="_blank">
                        <img height="20" src="/images/pancake-swap.png" />
                      </a>)
                    }
                    {
                      selectedProject.website &&
                      (<a href={selectedProject.website} target="_blank">
                        <i className="fas fa-globe-americas"></i>
                      </a>)
                    }

                    {
                      selectedProject.twitter &&
                      (<a href={selectedProject.twitter} target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>)
                    }
                    {
                      selectedProject.medium &&
                      (<a href={selectedProject.medium} target="_blank">
                        <i className="fab fa-medium-m"></i>
                      </a>)
                    }
                    {
                      selectedProject.telegram &&
                      (<a href={selectedProject.telegram} target="_blank">
                        <i className="fab fa-telegram-plane"></i>
                      </a>)
                    }
                  </div>
                </div>
              </div>
              <div className="right-area">
                <div className="r-top-area">
                  <h4>{selectedProject["name"]}</h4>
                  <div className="list">
                    <p>{selectedProject.description}</p>
                  </div>
                </div>
                <div className="r-bottom-area2">
                  {account &&
                    <div className="row mt-4">
                      <div className="col-xl-3 col-md-6 mb-3 mb-xl-0">
                        <span>Your balance</span>
                        <h4>{formatEtherDownRound(tokenRaiseBalance, projectRaiseTokenDecimals, 6)} {tokenRaiseSymbol}</h4>
                      </div>
                      <div className="col-xl-3 col-md-6 mb-3 mb-xl-0">
                        <span>Mint Price</span>
                        <h4>{mintPrice} {tokenRaiseSymbol}</h4>
                      </div>
                      <div className="col-xl-3 col-md-6">
                        <span>Your tier</span>
                        <h4>{tier || '-'}</h4>
                      </div>
                      <div className="col-xl-3 col-md-6">
                        {
                          (projectState == "O") &&
                          <>
                            {account ?
                              roundState !== 4 ?
                                <>
                                  <span dangerouslySetInnerHTML={{ __html: roundStateText }}></span>

                                  <h4 id={`idRoundTime-${contractAddress}`}>0h 0m 0s</h4>
                                </> :
                                <>
                                  <span>ROUND STATUS</span>
                                  <h4>ROUND CLOSED</h4>
                                </>
                              :
                              <>
                                <span>First Come First Serve <u>opens</u> in</span>
                
                                {/* <Countdown
                                  date={new Date(fcfsOpenTime * 1000)}
                                  intervalDelay={1}
                                  precision={3}
                                  renderer={renderCountDownRoundTime}
                                /> */}
                                <h4 id={`idFcfsOpenTime-${contractAddress}`}>0h 0m 0s</h4>
                              </>
                            }
                          </>
                        }

                        {
                          projectState == "F" &&
                          <>
                            {account ?
                              roundState !== 4 ?
                                <>
                                  <span dangerouslySetInnerHTML={{ __html: roundStateText }}></span>
                                  <Countdown
                                    date={new Date(roundTime * 1000)}
                                    intervalDelay={1}
                                    precision={3}
                                    renderer={renderCountDownRoundTime}
                                  />
                                </> :
                                <>
                                  <span>ROUND STATUS</span>
                                  <h4>ROUND CLOSED</h4>
                                </>
                              :
                              <>
                                <span>Allocation Round <u>close</u> in</span>
                                <Countdown
                                  date={new Date(closeTime * 1000)}
                                  intervalDelay={1}
                                  precision={3}
                                  renderer={renderCountDownCloseTime}
                                />

                              </>
                            }
                          </>
                        }
                        {
                          projectState === "C" && <>
                            <span>STATUS</span>
                            <h4>CLOSED</h4>
                          </>
                        }
                      </div>
                    </div>
                  }

                  {projectState !== "P" &&
                    <div className="row mt-4">
                      {
                        account && <>
                          <div className="col-xl-3 col-md-6 mb-3 mb-xl-0">
                            <span>Minted</span>
                            <h4 id="idUseParticipation">{userParticipation} {projectTokenSymbol}</h4>
                          </div>
                          <div className="col-xl-3 col-md-6 mb-3 mb-xl-0">
                            <span>Remaining allocation</span>
                            <h4 id="idBusdMaxBuy">{remainingAllocation} {projectTokenSymbol}</h4>
                          </div>
                        </>
                      }
                      {projectState !== "O" ? <>
                        <div className="col-xl-6 col-md-12 pb-xl-0 pb-5">
                          <span>NDO Progress</span>
                          <div className="pp-card-progress w-100">
                            <div className="pp-card-progress-percent pp-card-progress-percent-card" style={{ width: `${(totalNftsMinted / mintCap) * 100}%` }}></div>
                            <div className="pp-card-progress-label">
                              <span><b>{((totalNftsMinted / mintCap || 0) * 100).toFixed(2)}%</b></span>
                              <span><b>{totalNftsMinted}/{mintCap} {projectTokenSymbol}</b></span>
                            </div>
                          </div>
                        </div>
                      </> : <>
                        <div className="col-xl-6 col-md-12 pb-xl-0 pb-5">
                          <span>NDO Progress</span>
                          <div className="pp-card-progress text-center disabled w-100">
                            <div className="pp-card-progress-percent pp-card-progress-percent-card" style={{ width: `${(totalNftsMinted / mintCap) * 100}%` }}></div>
                            <div className="pp-card-progress-label">
                              <span className="participants-center" >Allocation round</span>
                              <span className="participants-center" style={{ top: "8px" }}><b>{get(selectedProject, "totalCountUserParticipated", "")}</b> Participants</span>
                            </div>
                          </div>
                        </div>
                      </>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="stf px-4">
              <h2 className="title mb-0">Collection Preview</h2>
              <div className="row mt-4">
                <div className="col-md col-6">
                  <div className="nft-image">
                    <img src={get(selectedProject, "gallery1", "")} />
                  </div>
                </div>
                <div className="col-md col-6">
                  <div className="nft-image">
                    <img src={get(selectedProject, "gallery2", "")} />
                  </div>
                </div>
                <div className="col-md col-6">
                  <div className="nft-image">
                    <img src={get(selectedProject, "gallery3", "")} />
                  </div>
                </div>
                <div className="col-md col-6">
                  <div className="nft-image">
                    <img src={get(selectedProject, "gallery4", "")} />
                  </div>
                </div>
                <div className="col-md col-6">
                  <div className="nft-image">
                    <img src={get(selectedProject, "gallery5", "")} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 pt-lg-5">
            <div className="container">
              <div className="row gx-xl-5">
                <div className="col-lg-6 pe-xl-5 mb-lg-0 mb-5">
                  <div className="row align-items-center mb-3">
                    <div className="col-12">
                      <h2 className="title mb-0">NDO Information</h2>
                    </div>
                  </div>
                  <div className="details-wrapper details-wrapper-auto">
                    <table className="table mb-0">
                      <tbody>
                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Opens
                          </td>
                          <td className="infor-section-value">
                            {openTime === undefined ? <span>TBA</span> : <span>{moment(openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            FCFS Opens
                          </td>
                          <td className="infor-section-value">
                            {fcfsOpenTime === undefined ? <span>TBA</span> : <span>{moment(fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC </span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Close
                          </td>
                          <td className="infor-section-value">
                            {closeTime === undefined ? <span>TBA</span> : <span>{moment(closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Mint Price
                          </td>
                          <td className="infor-section-value">
                            {openTime !== undefined ? <span style={{ textTransform: 'uppercase' }} id="idBusdConvert">{mintPrice} {tokenRaiseSymbol}</span> : <span style={{ textTransform: 'uppercase' }} id="idBusdConvert">TBA</span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Mint Cap
                          </td>
                          <td className="infor-section-value">
                            {openTime !== undefined ? <span style={{ textTransform: 'uppercase' }}>{mintCap} {projectTokenSymbol}</span> : <span style={{ textTransform: 'uppercase' }}>TBA</span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            NDO Participated
                          </td>
                          <td className="infor-section-value">
                            <span>{get(selectedProject, "totalCountUserParticipated", 0)}</span>
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            NDO Minted
                          </td>
                          <td className="infor-section-value">
                            {openTime !== undefined ? <span>{totalNftsMinted} {projectTokenSymbol}</span> : <span>TBA</span>}
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Raised
                          </td>
                          <td className="infor-section-value">
                            <span>{totalFundRaised} {tokenRaiseSymbol}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row mb-3 align-items-center">
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      <h2 className="title mb-0">NFT Information</h2>
                    </div>
                    {/* <div className="col-lg-6 text-lg-end text-center">
                      {
                        allocationInfo.length >= 0 &&
                        <a href="#" onClick={(e) => handleAddTokenToMetamask(e)} className="mybtn1 text-nowrap" style={{ fontSize: '16px' }}>
                          <span>Add token to <b>MetaMask</b></span>
                        </a>
                      }
                    </div> */}
                  </div>
                  <div className="details-wrapper">
                    <table className="table mb-0">
                      <tbody>
                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Name
                          </td>
                          <td className="infor-section-value">
                            <span>{selectedProject.name}</span>
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Token Symbol
                          </td>
                          <td className="infor-section-value">
                            <span>{get(selectedProject, "projectTokenSymbol", 0)}</span>
                          </td>
                        </tr>

                        <tr className="infor-section">
                          <td className="infor-section-title">
                            Total Mint
                          </td>
                          <td className="infor-section-value">
                            <span>{get(selectedProject, "totalSupply", 0)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <div className="row align-items-center mb-3">
                      <div className="col-12">
                        <h2 className="title mb-0">Schedule</h2>
                      </div>
                    </div>
                    <div className="details-wrapper">
                      <ScheduleTabComponent roundInfo={roundInfo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {nftLaunchTime > 0 &&
            <div className="text-center mt-5 pt-5 mb-5 mb-lg-0">
              <h2 className="title mb-0 fs-40" id="idLaunchTime"></h2>
              {/* <Countdown
                date={new Date(nftLaunchTime * 1000)}
                intervalDelay={1}
                precision={3}
                renderer={renderCountLaunchTime}
              /> */}
            </div>
          }
          {yourAllocationVisible && <div className="mt-4 pt-0 pt-lg-5">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-lg-4">
                  <h2 className="title mb-0">My NFTs</h2>
                </div>
              </div>
              <div className="details-wrapper details-wrapper-2">
                <div className="pt-4 pb-2">
                  <YourAllocationComponent
                    launchTime = {nftLaunchTime*1000}
                    allocationInfo={allocationInfo}
                    tokenSymbol={tokenRaiseSymbol}
                    decimals={projectRaiseTokenDecimals}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                  />
                  {/* {
                    allocationInfo.length > 0 && <div className="text-center">
                      <button href="#" onClick={(e) => handMint(e)} className="mybtn2 text-nowrap" style={{ width: '270px' }}>
                        <span>Mint</span>
                      </button>
                    </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
          }
        </div> :
        <div className="pp-detail-page">
          <div className="pp-detail-banner">
            <div className="container text-muted">
              Loading...
            </div>
          </div>
        </div>
      }

      <InvestmentModal
        nftPrice={mintPrice}
        remainingAllocation={remainingAllocation}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenRaiseBalance={helpers.formatNumberDownRound(tokenRaiseBalance, 6)}
        symbol={selectedProject?.symbol}
      />

      {/* <ApproveModal walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenRaiseBalance={helpers.formatNumberDownRound(tokenRaiseBalance, 4)} /> */}


    </>
  );
};

export default ProjectDetailPage;
