import React, { useEffect, useState, } from "react";
// import BlockUi from "react-block-ui";
// import "react-block-ui/style.css";
import { get } from "lodash";
import { ACTION_CONST, ACTION_STATUS, MESSAGE } from "../../constants";
// import { actGetListProjects } from "../../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { formatEtherDownRound, helpers } from "../../utils/helpers";
import { useNdoWalletInfo, useProjectTokenRaiseSymbol, useSelectedProject, useWeb3Utils } from "../../hook/useState";
import { participateNdo } from "../../utils/ndoContractHelper";

import { useActiveWeb3React } from "../../hook";
import exactMath from 'exact-math';

const InvestmentModal = ({ remainingAllocation, tokenRaiseBalance, nftPrice }) => {
  const dispatch = useDispatch();
  // const [showBlockUI, setShowBlockUI] = useState(false);
  const selectedProject = useSelectedProject();
  // const ndoInfoWallet = useNdoWalletInfo();
  // const web3Utils = useWeb3Utils();
  const projectRaiseTokenDecimals = get(selectedProject, "raiseTokenDecimal", 18);

  const [amountTokenRaise, setAmountTokenRaise] = useState(0);
  const [numberNft, setNumberNft] = useState(0)

  const symbol = useProjectTokenRaiseSymbol();
  const projectName = get(selectedProject, "name", "");


  const [enableBuyBtn, setEnableBuyBtn] = useState(false);

  const { account, library } = useActiveWeb3React()

  // console.log("mintPrice==>", nftPrice);

  //add function click max button
  const handleClickMax = () => {

    let maxNfts = 0
    const tokenRaiseBalanceCal = exactMath.div(tokenRaiseBalance, 10 ** projectRaiseTokenDecimals)
    const remainingAllocationAmount = nftPrice * remainingAllocation;

    if (remainingAllocationAmount > 0 && remainingAllocationAmount >= tokenRaiseBalanceCal) {

      maxNfts = Math.floor(exactMath.div(tokenRaiseBalanceCal, nftPrice));

      document.getElementById("inputNumberNftsMint").value = maxNfts
      setAmountTokenRaise(exactMath.mul(maxNfts, nftPrice))
      setNumberNft(maxNfts)
      setEnableBuyBtn(true);
      return;
    }
    if (remainingAllocationAmount > 0 && remainingAllocationAmount < tokenRaiseBalanceCal) {
      maxNfts = Math.floor(exactMath.div(remainingAllocationAmount, nftPrice));
      document.getElementById("inputNumberNftsMint").value = maxNfts
      setAmountTokenRaise(exactMath.mul(maxNfts, nftPrice))
      setNumberNft(maxNfts)
      setEnableBuyBtn(true);
      return;
    }
    setEnableBuyBtn(false);
  }


  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);

    const remainingAllocationAmount = exactMath.mul(amountInputValue, nftPrice)

    const tokenRaiseBalanceCal = exactMath.div(tokenRaiseBalance, 10 ** projectRaiseTokenDecimals)



    if (0 < amountInputValue && amountInputValue <= remainingAllocation
      && remainingAllocationAmount <= tokenRaiseBalanceCal) {

      setEnableBuyBtn(true);

    } else {
      setEnableBuyBtn(false)
    }

    setNumberNft(amountInputValue)
    setAmountTokenRaise(exactMath.mul(amountInputValue, nftPrice))


    return;

  }

  const handleBuyClick = () => {

    if (account && selectedProject && library) {

      dispatch({ type: ACTION_CONST.SHOW_MODAL_SUBMIT });

      participateNdo(library.provider, selectedProject.contract, account, numberNft, amountTokenRaise, data => {
        if (data.status == ACTION_STATUS.PARTICIPATE_SUCCESS) {

          dispatch({ type: ACTION_CONST.HIDE_MODAL_SUBMIT })
          dispatch({ type: ACTION_CONST.REQUEST_SUCCESS });
          dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: data.txID });
          setEnableBuyBtn(false);
          document.getElementById("inputNumberNftsMint").value = 0;

          // dispatch({
          //   type: ACTION_CONST.ALERT_SUCCESS,
          //   message: "Successfully Joined Pool"
          // })
        }

        if (data.status == ACTION_STATUS.PARTICIPATE_FAIL) {
          debugger
          dispatch({ type: ACTION_CONST.HIDE_MODAL_SUBMIT })
          dispatch({ type: ACTION_CONST.REQUEST_FAIL });
          dispatch({ type: ACTION_CONST.SET_MESSAGE, data: data.message });
          // dispatch({
          //   type: ACTION_CONST.ALERT_FAILS,
          //   message: "Failed to Join Pool"
          // })
        }
        if (data.status == ACTION_STATUS.ESTIMATE_GAS_FAILS) {
          dispatch({ type: ACTION_CONST.REQUEST_FAIL });
          dispatch({ type: ACTION_CONST.SET_MESSAGE,  data: data.message });
        }
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="buyModal"
      tabIndex="-1"
      aria-labelledby="buyModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="buyModalLabel">Mint {projectName}</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body px-0 py-4">
            <div className="row">
              <div className="col-md-6 mb-4">
                <label className="form-label text-label">Your balance: </label>
                <h3 className="text-white mb-0 fs-24">{formatEtherDownRound(tokenRaiseBalance, projectRaiseTokenDecimals, 6)} {symbol}</h3>
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label text-label">Remaining Allocation: </label>
                <h3 className="text-white mb-0 fs-24">{remainingAllocation}</h3>
              </div>
            </div>

            <div className="form-group">
              {/* <label className="form-label text-label">Number NFTs:</label> */}
              <div className="input-group">
                <input type="number" id="inputNumberNftsMint" className="form-control fs-24" defaultValue={0} onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                  autoFocus onChange={(e) => handleOnchangeAmount(e)} />
                <button className="mybtn1 no-bg" onClick={() => handleClickMax()}> Max</button>
              </div>
              <label className="form-label text-label mt-2">Total Cost: {amountTokenRaise} {symbol}</label>
            </div>
            <div className="get-start mt-4 text-center mb-2 d-flex" style={{ columnGap: '10px' }}  >
              <button className="mybtn2 w-100 no-bg" disabled={!enableBuyBtn} onClick={() => handleBuyClick()} data-bs-dismiss="modal">
                Mint
              </button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentModal;
