export const extensionName = {
    metamask: "METAMASK",
    binanceExtension: "BINANCE_EXTENSION",
    trustWallet: "TRUST_WALLET",
};



export const MESSAGE = {
    APPROVAL_FAILS: 'Failed to Approve Tokens!',
    APPROVE_SUCCESS: 'Approve Tokens successfully!',

    DEPOSIT_STAKE_FAIL:'Deposit stake fail!',
    DEPOSIT_STAKE_SUCCESS: 'Deposit stake successfully!',

    UNSTAKE_FAIL:'Init Unstake fail!',

    WITHDRAW_FAIL:'Withdraw stake fail!',


    ADD_TOKEN_METAMASK_FAIL:'Add token to metamask fail!',
    ADD_TOKEN_METAMASK_SUCCESS:'Add token to metamask successfully!',
    ESTIMATE_GAS_FAILS: 'Unable to participate in NDO. Please try submitting tx again if you have a valid remaining allocation.',


    PARTICIPATE_NDO_SUCCESS: 'Successfully Joined Pool',
    PARTICIPATE_NDO_FAIL: 'Failed to Join Pool '
    
}

export const STATE ={
    NONE:"none",
    SUCCESS:'success',
    ERROR: 'error',
    SUBMIT:'submit'
}