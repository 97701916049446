import React from 'react';
import Header from './shared/layout/Header';
import Footer from './shared/layout/Footer';
import BlockUi from "react-block-ui"
import "react-block-ui/style.css";
import { useBlockingUI } from '../hook/useState';
export const PrivateLayout = ({ children, ...rest }) => {

    const showBlockUI = useBlockingUI();
    return (
        <>
            <BlockUi tag="div" blocking={showBlockUI}>
                <Header />
                <main>
                    {children}
                </main>
                <Footer />
            </BlockUi>
        </>
    );
};