import React from 'react'
import { useActiveWeb3React } from '../../../hook';
import { getBscScanLink } from '../../../utils/utils';
import ContentHeader from './ContentHeader'

const TransactionSubmittedContent = ({ onDismiss, hash , message}) => {

  const { chainId } = useActiveWeb3React();
  
  return (
    <div className="modal-wrap">
      <div className="modal-section">
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <div className="confirm-icon">
          <i className="fas fa-check" style={{ fontSize: '97px', color: '#49ffa1' }}></i>
        </div>
        <div className="text-center">
          {chainId &&
            <>
              {
                hash &&
                <a target="_blank" className="text-white text-decoration-none" href={getBscScanLink(chainId, hash, 'transaction')}>View on Explorer</a>
              }
              {
                chainId &&
               
                  <div style={{ fontSize: '18px' }} className="text-white text-decoration-none">{message}</div>
              }

            </>
          }
        </div>
        <div className="mt-4 text-center  mx-auto">
          <button className="mybtn1 no-bg px-5" type="button" onClick={onDismiss}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default TransactionSubmittedContent
